import { ADD_PATH, SET_REQUEST_TYPE, SET_REQUEST_UUID } from "./actionTypes";

const initialState = {
  path: false,
  requestType: 'CREATE',
  requestUUID: null
};

export default function history(state = initialState, action) {
  switch (action.type) {
    case ADD_PATH:
      return { ...state ,path: action.payload };
    case SET_REQUEST_TYPE:
      return { ...state ,requestType: action.payload };
    case SET_REQUEST_UUID:
      return { ... state, requestUUID : action.payload };
    default:
      return { ...state };
  }
}