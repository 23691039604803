export default (permission) => {
  const permissions = localStorage.getItem("permissions");
  if (permissions) {
    return permissions.includes("ADMIN")
      ? true
      : permissions.includes(permission)
      ? true
      : false;
  }
};
