import { ADD_COMPETITORS, STEPS, SHOW_COMPETITORS_SOCIALS } from "./actionTypes";

const initialState = {
  competitors: {},
  steps: 0,
  show_socials : false
};

export default function user(state = initialState, action) {
  switch (action.type) {
    case ADD_COMPETITORS:
      return { ...state ,competitors: action.payload };
    case STEPS:
      return { ...state, steps: action.payload}
    case SHOW_COMPETITORS_SOCIALS:
      return { ...state, show_socials: action.payload}
    default:
      return { ...state };
  }
}