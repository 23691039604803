import React, { lazy, Suspense, Component } from "react";
import { Router } from "@reach/router";
import { connect } from "react-redux";
import { setLoggedIn, setUser } from "../ducks/user/actions";
import checkPermissions from "../utils/checkPermissions";

const LandingPage = lazy(() =>
  import("../containers/LandingPage/landing-page")
);

const AdminLogin = lazy(() => import("../containers/Admin/Login/login"));

const LoginPage = lazy(() => import("../containers/Login/login"));

const SignUp = lazy(() => import("../containers/Signup/signup"));

const BrandlyUrl = lazy(() => import("../containers/BrandlyUrlPage/index"));

const SocialLinksPage = lazy(() =>
  import("../containers/SocialLinksPage/social-links-page")
);
const ViewDeMoPage = lazy(() =>
  import("../containers/ViewDemoPage/view-demo-page")
);

const EmailVerificatoinPage = lazy(() => import("../containers/EmailVerificatoinPage/index"));

const VerificationSuccess = lazy(() => import("../components/EmailVerifiedView/email-verified-view"))

const ResetPasswordPage = lazy(() =>
  import("../containers/ResetPasswordPage/index")
);
const SetNewPassword = lazy(() =>
  import("../containers/ResetPasswordPage/setnewpassword")
);

const Admin = lazy(() => import("../containers/Organization/User/index"));

const Dashboard = lazy(() => import("../components/admin/dashboard/Dashboard"));

const AnalyticsDashboard = lazy(() =>
  import("../containers/AnalysisPage/analysis-page")
);

const RequestListPage = lazy(() =>
  import("../containers/RequestsListPage/index")
);

const ReportListPage = lazy(() =>
  import("../containers/ReportListPage/index")
);

const NotFoundPage = lazy(() =>
  import("../components/DefaultPage/default-page")
);

const ContactUsPage = lazy(() =>
  import("../containers/ContactUsPage/contact-us-page")
);

const PageLoadingView = lazy(() =>
  import("../components/PageLoaderView/page-loader-view")
);

const RoleListPage = lazy(() =>
  import("../containers/UserRolesListPage/user-roles-list-page")
);

const AdminUsers = lazy(() =>
  import("../containers/Organization/AdminUser/index")
);
const AccountSetting = lazy(() =>
  import("../containers/UserProfilePage/user-profile-page")
);
const PasswordResetSuccess = lazy(() =>
  import("../containers/PasswordRestSuccess/index")
);

const SuperFan = lazy(() =>
  import('../containers/SuperFan')
);

const Benchmark = lazy(() => import("../containers/BenchmarkPage/benchmark"));

const ExportPage = lazy(() => import("../containers/ExportsPage/exports-page"));

class Routes extends Component {
  componentDidMount() {
    let token = localStorage.getItem("token");
    let user = localStorage.getItem("user");
    if (token && user) {
      this.props.setUser(JSON.parse(user));
      this.props.setLoggedIn(true);
    } else {
      this.props.setLoggedIn(false);
    }
  }

  render() {
    const token = localStorage.getItem("token");
    const role = localStorage.getItem("role");
    return (
      <Suspense fallback={<PageLoadingView />}>
        <Router>
          {role !== "ADMIN" && (
            <>
              <LandingPage path="/"></LandingPage>
              <SignUp path="/signup"></SignUp>
              <LoginPage default path="/login"></LoginPage>
              <ViewDeMoPage path="/demo"></ViewDeMoPage>
              <SocialLinksPage path="/socials"></SocialLinksPage>
              <EmailVerificatoinPage path="/emailverification"></EmailVerificatoinPage>
              <ReportListPage path="/reportlist"></ReportListPage>
              <BrandlyUrl path="/brandlyurl"></BrandlyUrl>
              <ContactUsPage path="/contact-us"></ContactUsPage>
            </>
          )}
          <SuperFan path="/super-fan" />
          <VerificationSuccess path="/success"></VerificationSuccess>
          <PasswordResetSuccess path="/resetpasswordsuccess"></PasswordResetSuccess>
          <AccountSetting path="/edit-profile"></AccountSetting>
          <ResetPasswordPage path="/forgot-password"></ResetPasswordPage>
          <SetNewPassword
            exact
            path="/reset-password"
          ></SetNewPassword>
          <SetNewPassword
            exact
            isAdmin={true}
            path="/set-password/:set_token"
          ></SetNewPassword>
          <Dashboard role={role} path="/dashboard">
            <AnalyticsDashboard path="/analysis/:request_id"></AnalyticsDashboard>
            <AnalyticsDashboard path="/analysis/"></AnalyticsDashboard>
            <AnalyticsDashboard
              public={true}
              path="/public/:public_request_uuid"
            ></AnalyticsDashboard>
            {token && this.props.isLoggedIn && role === "USER" ? (
              <RequestListPage default path="/"></RequestListPage>
            ) : (
                token &&
                this.props.isLoggedIn &&
                role === "ADMIN" && (
                  <>
                    {checkPermissions("LIST_REQUESTS") && (
                      <RequestListPage path="/requests"></RequestListPage>
                      // <ReportListPage path="/requests"></ReportListPage>
                    )}
                    {checkPermissions("LIST_USERS") && (
                      <Admin default path="/"></Admin>
                    )}
                    {checkPermissions("READ_ROLE") && (
                      <RoleListPage path="/roles" exact></RoleListPage>
                    )}
                    {checkPermissions("LIST_USERS") && (
                      <AdminUsers path="/admin-users" exact></AdminUsers>
                    )}
                    {checkPermissions("EXPORT_DATA") && (
                      <ExportPage path="/export-data" exact />
                    )}
                    {checkPermissions("ADD_BENCHMARK") && (
                      <Benchmark path="/benchmark" exact />
                    )}
                  </>
                )
              )}
          </Dashboard>
          <AdminLogin
            path="/admin/login"
            default={role === "ADMIN" ? true : false}
            exact
          />
          <NotFoundPage path="/not-found" exact></NotFoundPage>
        </Router>
      </Suspense>
    );
  }
}

const mapStateToProps = (data) => {
  return {
    isLoggedIn: data.user.loggedIn,
    role: localStorage.getItem("role"),
  };
};

const mapDispatchToProps = (dispatch) => ({
  setLoggedIn: (data) => dispatch(setLoggedIn(data)),
  setUser: (data) => dispatch(setUser(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
