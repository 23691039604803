import {
  LOGIN_USER,
  SET_USER_ROLE,
  LOGOUT_USER,
  SET_LOGGED_IN
} from "./actionTypes";

const initialState = {
  currentUser: {},
  loggedIn: false,
  role: "USER"
};

export default function user(state = initialState, action) {
  switch (action.type) {
    case LOGIN_USER:
      return { ...state, currentUser: action.payload };
    case LOGOUT_USER:
      return { ...state, currentUser: {} };
    case SET_LOGGED_IN:
      return { ...state, loggedIn: action.payload };
    case SET_USER_ROLE:
      return { ...state, role: action.payload };
    default:
      return { ...state };
  }
}
