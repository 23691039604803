// * Action types
import {
  LOGIN_USER,
  SET_USER_ROLE,
  LOGOUT_USER,
  SET_LOGGED_IN
} from "./actionTypes";

export const setUser = data => {
  return {
    type: LOGIN_USER,
    payload: data
  };
};

export const removeUser = () => {
  return {
    type: LOGOUT_USER,
    payload: {}
  };
};

export const setUserRole = data => {
  return {
    type: SET_USER_ROLE,
    payload: data
  };
};

export const setLoggedIn = data => {
  return {
    type: SET_LOGGED_IN,
    payload: data
  };
};
