import { ADD_SOCIALS,  } from "./actionTypes";

const initialState = {
  user_brand: {
    url: '',
    type: 'brand',
    socialLinks: []
  },
};

export default function socials(state = initialState, action) {
  switch (action.type) {
    case ADD_SOCIALS:
      return { ...state ,user_brand: action.payload };
    default:
      return { ...state };
  }
}